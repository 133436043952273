import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import TopHeader from "../../../components/top-header"

import { Row, Container, Col } from 'react-bootstrap/'
import RetmesKullanici from "../../../images/kullanici.png"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const Kullanici = ({ intl }) => {
  const title = intl.formatMessage({ id: "modules_content4__10" })
  const data = useStaticQuery(graphql`
  query {
    headerImage: file(relativePath: { eq: "modules/Yonetim.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return <Layout>
    <TopHeader />
    <SEO title={title} />
    <Container>
    
      <div className='image-wrapper' fluid>
        <Img style={{ borderRadius: '1rem' }} alt="retmes yönetim" fluid={data.headerImage.childImageSharp.fluid} />
        <h2 className='image-wrapper-text'><FormattedMessage id="modules_title4__1" /></h2>
      </div>
      <h1 className='page-title'>{title}</h1>
      <Row className='content-page-row'>
        <Col sm={8}>
          <p className='content-page-p'>
            <FormattedMessage id="modules_kuly_desc" />
          </p>
        </Col>
        <Col sm={4}>
          <img style={{ width: '500px' }} src={RetmesKullanici} alt={title} />
        </Col>
      </Row>
      <Row style={{ height: '50vh' }}>
      </Row>
    </Container>
  </Layout>
}

export default injectIntl(Kullanici) 